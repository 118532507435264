import React from 'react';

import Involve from '@/assets/involve.svg';
import Headspace from '@/assets/headspace.svg';
import Legartis from '@/assets/legartis.svg';
import Logo from '@/assets/logo.svg';
import Mc from '@/assets/mc.svg';
import Musixmatch from '@/assets/musixmatch.svg';
import Okkular from '@/assets/okkular.svg';
import Spectrum from '@/assets/spectrum.svg';
import Uniphone from '@/assets/uniphone.svg';

import Tc from '@/assets/tc.svg';
import Bi from '@/assets/bi.svg';
import Crn from '@/assets/crn.svg';
import Forbes from '@/assets/forbes.svg';
import Impact from '@/assets/impact.svg';
import Tbp from '@/assets/tbp.svg';

const logos = [<Tc />, <Forbes />, <Bi />, <Impact />, <Tbp />, <Crn />];

const images = [
  <Involve />,
  <Headspace />,
  <Legartis />,
  <Logo />,
  <Mc />,
  <Musixmatch />,
  <Okkular />,
  <Spectrum />,
  <Uniphone />,
];

const ScrollLogos = () => {
  return (
    <div className="flex items-center justify-center">
      {/* 1. */}
      <div className="animate-wrapper relative w-[100%] max-w-1680 overflow-hidden">
        {/* 2. */}
        <div className="animate animate-carousel absolute left-0 flex w-full items-center justify-start gap-10">
          {/* 3 */}
          {images.map((i) => {
            return (
              <div className="flex w-[20rem] items-start justify-center">
                {i}
              </div>
            );
          })}
          {images.map((i) => {
            return (
              <div className="flex w-[20rem] items-start justify-center">
                {i}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Logos = ({ logos }: { logos: { asset: { url: string } }[] }) => {
  return (
    <div className="flex items-center justify-center">
      {/* 1. */}
      <div className="logos animate-wrapper relative flex w-full max-w-1680 flex-row overflow-hidden">
        {/* 2. */}
        <div className="logos-slide white flex flex-row items-center whitespace-nowrap">
          {/* 3 */}
          {logos.map((i, idx) => {
            return (
              <div
                className="mx-12 flex h-12 items-center justify-center"
                key={`client-logo-${idx}`}
              >
                <img
                  className="h-12 max-w-36 object-contain"
                  src={i.asset.url}
                />
              </div>
            );
          })}
        </div>
        <div className="logos-slide white flex flex-row items-center whitespace-nowrap">
          {logos.map((i, idx) => {
            return (
              <div
                className="mx-12 flex h-12 items-center justify-center"
                key={`client-logo-${idx}`}
              >
                <img
                  className="h-12 max-w-36 object-contain"
                  src={i.asset.url}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { Logos };
